body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background: black;
  display: grid;
  justify-items: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100vh;
  grid-template-columns: auto;
  grid-template-rows: 1fr;
}

#breath-light {
  margin: auto;
  /* margin-top: 150px; */
  height: 6px;
  width: 50vw;
  border-radius: 3px;
  background: white;

  box-shadow: 0 0 60px #ffffff;

  -webkit-animation: breath 6s;
  animation: breath 6s;

  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-webkit-keyframes breath {
  0% {
    opacity: 0.1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
@keyframes breath {
  0% {
    opacity: 0.1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}

