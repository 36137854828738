body {
  background: black;
  display: grid;
  justify-items: center;
  align-items: center;
  height: 100vh;
  grid-template-columns: auto;
  grid-template-rows: 1fr;
}

#breath-light {
  margin: auto;
  /* margin-top: 150px; */
  height: 6px;
  width: 50vw;
  border-radius: 3px;
  background: white;

  box-shadow: 0 0 60px #ffffff;

  -webkit-animation: breath 6s;
  animation: breath 6s;

  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-webkit-keyframes breath {
  0% {
    opacity: 0.1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
@-o-keyframes breath {
  0% {
    opacity: 0.1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
@-moz-keyframes breath {
  0% {
    opacity: 0.1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
@keyframes breath {
  0% {
    opacity: 0.1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
